<template>
  <div id="track-back">
    <div>
      <div class="website" v-if="isShow === 0">
        <img src="@/assets/images/zzjd-logo.png" alt="" />
        <div>
          <p v-if="zzjd">
            {{ zzjd.orgName ? zzjd.orgName : "种植基地" }}官网：
          </p>
          <p v-else>种植基地官网：</p>
          <a :href="zzjd ? zzjd.url : ''" target="_blank">{{
            zzjd ? zzjd.url : ""
          }}</a>
        </div>
      </div>
      <div class="website" v-else-if="isShow === 1">
        <img src="@/assets/images/yjrz-logo.png" alt="" />
        <div>
          <p v-if="yjrz.length">
            {{
              yjrz[0].issuingOrganization
                ? yjrz[0].issuingOrganization
                : "有机认证"
            }}官网：
          </p>
          <p v-else>有机认证官网：</p>
          <a
            :href="yjrz.length ? yjrz[0].issuingOrganizationWebsite : ''"
            target="_blank"
            >{{ yjrz.length ? yjrz[0].issuingOrganizationWebsite : "" }}</a
          >
        </div>
      </div>
      <div class="website" v-else-if="isShow === 2">
        <img src="@/assets/images/jcrz-logo.png" alt="" />
        <div>
          <p v-if="jcrz">
            {{ jcrz.testOrg ? jcrz.testOrg : "检测认证" }}官网：
          </p>
          <p v-else>检测认证官网：</p>
          <a :href="jcrz ? jcrz.officialWebsite : ''" target="_blank">{{
            jcrz ? jcrz.officialWebsite : ""
          }}</a>
        </div>
      </div>
      <div class="website" v-else-if="isShow === 3">
        <img src="@/assets/images/gc-logo.png" alt="" />
        <div>
          <p v-if="gc.length">
            {{ gc[0].factoryName ? gc[0].factoryName : "品牌工厂" }}官网：
          </p>
          <p v-else>品牌工厂官网：</p>
          <a
            :href="gc.length ? gc[0].officialWebsiteUrl : ''"
            target="_blank"
            >{{ gc.length ? gc[0].officialWebsiteUrl : "" }}</a
          >
        </div>
      </div>
      <div class="website" v-else-if="isShow === 9">
        <img src="@/assets/images/bx-logo.png" alt="" />
        <div>
          <p>保险官网：</p>
          <a :href="bx ? bx.policyLink : ''" target="_blank">{{
            bx ? bx.policyLink : ""
          }}</a>
        </div>
      </div>
      <div class="website" v-else-if="isShow === 10">
        <img src="" alt="" />
        <div>
          <p>消费者官网：</p>
          <a :href="bx ? bx.policyLink : ''" target="_blank">{{
            bx ? bx.policyLink : ""
          }}</a>
        </div>
      </div>
      <div class="website" v-else>
        <img :src="other[isShow].trademark" alt="" />
        <div>
          <p>{{ other[isShow].cont }}官网：</p>
          <a :href="other[isShow].url" target="_blank">{{
            other[isShow].url
          }}</a>
        </div>
      </div>
      <div class="content_box" ref="content">
        <div ref="nav" class="nav">
          <div>
            <ul>
              <li
                :class="[index === isShow ? 'onLi' : '']"
                v-for="(list, index) in other"
                :key="index"
              >
                <svg-icon :icon-class="list.icon ? list.icon : ''" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div class="content" v-show="isShow === 0">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div class="zzjd" v-if="zzjd">
              <table border="1">
                <caption>
                  种植基地
                </caption>
                <tr>
                  <td>IP地址</td>
                  <td></td>
                  <td>统一社会信用代码</td>
                  <td>{{ zzjd.orgCode }}</td>
                </tr>
                <tr>
                  <td>企业名称</td>
                  <td>{{ zzjd.enterpriseName }}</td>
                  <td>法人代表人</td>
                  <td>{{ zzjd.legalPersonName }}</td>
                </tr>
                <tr>
                  <td>种植基地地址</td>
                  <td colspan="3">{{ zzjd.address }}</td>
                </tr>
                <tr>
                  <td>土壤</td>
                  <td>{{ zzjd.soil }}</td>
                  <td>水文</td>
                  <td>{{ zzjd.hydrology }}</td>
                </tr>
                <tr>
                  <td>光照</td>
                  <td>{{ zzjd.illumination }}</td>
                  <td>气温</td>
                  <td>{{ zzjd.airTemperature }}</td>
                </tr>
                <tr>
                  <td>空气温度</td>
                  <td>{{ zzjd.airHumidity }}</td>
                  <td>地形</td>
                  <td>{{ zzjd.landForm }}</td>
                </tr>
                <tr>
                  <td colspan="4" style="padding: 20px">
                    <img :src="zzjd.file" alt="" style="width: 100%" />
                  </td>
                </tr>
              </table>
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div class="content" v-show="isShow === 1">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div v-if="yjrz.length">
              <img :src="yjrz[0].certificateUrl" alt="有机认证" />
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div class="content" v-show="isShow === 2">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div
              style="width: 727px; height: 1030px"
              v-if="jcrz"
              v-loading="loadedRatio !== 1"
            >
              <pdf
                v-for="item in numPages"
                v-show="loadedRatio === 1"
                :key="item"
                :src="jcrz.url"
                :page="item"
                @progress="loadedRatio = $event"
              />
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div class="content" v-show="isShow === 3">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div class="gc" v-if="gc.length">
              <table border="1">
                <caption>
                  品牌工厂
                </caption>
                <tr>
                  <td>IP地址</td>
                  <td></td>
                  <td>统一社会信用代码</td>
                  <td>{{ gc[0].creditCode }}</td>
                </tr>
                <tr>
                  <td>企业名称</td>
                  <td>{{ Store.enterpriseName }}</td>
                  <td>法定代表人</td>
                  <td>{{ gc[0].legalPerson }}</td>
                </tr>
                <tr>
                  <td>工厂地址</td>
                  <td colspan="3">
                    {{ gc[0].factoryAddress }}
                  </td>
                </tr>

                <tr>
                  <td>工厂人数</td>
                  <td>{{ gc[0].personNumber }}</td>
                  <td>生产设备</td>
                  <td>{{ gc[0].productionEquipment }}</td>
                </tr>
                <tr>
                  <td>生产工艺</td>
                  <td colspan="3" style="height: 80px">
                    {{ gc[0].productionProcess }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="padding: 20px">
                    <img
                      :src="gc[0].informationUrl"
                      alt=""
                      style="width: 100%"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div class="content" v-show="isShow === 9">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div v-if="bx">
              <img :src="bx.policyAttachment" alt="保险" />
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div class="content" v-show="isShow === 10">
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>
            <div v-if="Store" class="Box">
              <div class="cont1">
                <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
                <p>{{ CommodityDetails.manufacturer }}</p>
                <h3>
                  －{{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </h3>
              </div>
              <div class="cont2">
                <ul style="margin-bottom: 17px">
                  <li>商品编号</li>
                  <li>单价</li>
                  <li>数量</li>
                  <li>小计</li>
                </ul>
                <ul>
                  <li>品名</li>
                  <li>规格</li>
                  <li>单位</li>
                  <li>批号</li>
                  <li>厂家</li>
                  <li>产地</li>
                </ul>
              </div>
              <div class="cont3">
                <ul>
                  <li>{{ CommodityDetails.barcode }}</li>
                  <li>
                    {{
                      Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                        .retailPrice | Price
                    }}
                  </li>
                  <li>1.0</li>
                  <li>
                    {{
                      Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                        .retailPrice | Price
                    }}
                  </li>
                </ul>
                <ul>
                  <li class="name" :title="CommodityDetails.commodityName">
                    {{ CommodityDetails.commodityName }}
                  </li>
                  <li>{{ CommodityDetails.specsParameter }}</li>
                  <li>{{ CommodityDetails.prickle }}</li>
                  <li>批号：20200803</li>
                </ul>
                <ul style="margin-bottom: 0">
                  <li class="storeName" :title="CommodityDetails.manufacturer">
                    {{ CommodityDetails.manufacturer }}
                  </li>
                  <li>{{ CommodityDetails.province }}</li>
                </ul>
              </div>
              <div class="cont4">
                <div>
                  <p>当前状态</p>
                  <span>支付成功</span>
                </div>
                <div>
                  <p>商家</p>
                  <a :href="manufacturer ? manufacturer : ''" target="_blank">{{
                    CommodityDetails.manufacturer
                  }}</a>
                </div>
                <div>
                  <p>支付时间</p>
                  <span>{{ CommodityDetails.createTime | nowTime }}</span>
                </div>
                <div>
                  <p>支付方式</p>
                  <span>广州银行储蓄卡（1618）</span>
                </div>
                <div>
                  <p>交易单号</p>
                  <span>4200000837202101126383104318</span>
                </div>
                <div style="margin-bottom: 100px">
                  <p>商户单号</p>
                  <span>可在支持的商户扫码退款</span>
                </div>
                <img src="@/assets/images/xfz-.png" alt="" />
                <p style="font-size: 24px; margin-top: 5px; color: #333333">
                  48956212354798622021320141
                </p>
              </div>
              <div class="cont5">
                <div>
                  <p>电子保单</p>
                  <span>1587588455464554</span>
                </div>
                <div>
                  <p>保单机构</p>
                  <a
                    href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                    target="_blank"
                    >国任保险</a
                  >
                </div>
                <div>
                  <p>有效时间</p>
                  <span
                    >{{ CommodityDetails.createTime | nowDay }}至{{
                      CommodityDetails.createTime | oneYear
                    }}</span
                  >
                </div>
                <div>
                  <p>物流轨迹</p>
                  <a @click="isShow = 7">商品全生命周期在线检索</a>
                </div>
              </div>
            </div>
            <img src="@/assets/images/qs.png" alt="缺省页" v-else />
          </div>
        </div>
        <div
          class="content"
          v-show="isShow === index"
          v-for="(list, index) in other"
          :key="index"
        >
          <div class="imgBox" ref="IMG">
            <div class="arrow-left arrow" @click="up">
              <img src="@/assets/images/Goods-left.png" />
            </div>
            <div class="arrow-right arrow" @click="down">
              <img src="@/assets/images/Goods-right.png" />
            </div>

            <img
              :src="img"
              alt=""
              class="documents"
              :style="
                img === require('@/assets/images/qs.png') ? 'width:auto' : ''
              "
              v-for="(img, i) in other[index].img"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import { formatDate, formatDay2 } from "@/utils/validate";

export default {
  name: "track-back",
  components: {
    pdf,
  },
  computed: {
    ...mapState("personalUser", ["userLogin"]),
    ...mapState("user", ["isLogin"]),
  },
  data() {
    return {
      other: [
        {
          cont: "种植基地",
          img: [require("@/assets/images/qs.png")],
          odd: "80025668823062662003",
          // url: "http://taisheng.realbrand.net",
          icon: "zz_01icon",
          // trademark: require("@/assets/images/zzjd-logo.png"),
        },
        {
          cont: "有机认证",
          img: [require("@/assets/images/qs.png")],
          // odd: "80025668823062662003",
          // url: "http://cx.cnca.cn/CertECloud/resultSn/skipResultSnFull",
          icon: "TrackBack-test",
          // trademark: require("@/assets/images/yjrz-logo.png"),
        },
        {
          cont: "检测认证",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "工厂",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "gc_04icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/qs.png")],
          odd: "CY202103061006",
          // url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          // trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "物流",
          img: [require("@/assets/images/qs.png")],
          odd: "FD54045 / 76F01",
          // url: "https://www.ky-express.com",
          icon: "wl_06icon",
          // trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "销售",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "零售店",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "ls_08icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.boc.cn",
          icon: "yh_09icon",
          // trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          // trademark: require("@/assets/images/bx-logo.png"),
        },
        {
          cont: "消费者",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.boc.cn",
          icon: "xfz_11icon",
          // trademark: require("@/assets/images/test.png"),
        },
      ],
      isShow: 0,
      jcrz: "",
      zzjd: "",
      yjrz: "",
      gc: "",
      bx: "",
      Store: "",
      CommodityDetails: "",
      authCodeState: false,
      pdfSrc: "",
      numPages: "",
      manufacturer: "#",
      // 加载进度
      loadedRatio: 0,
    };
  },
  filters: {
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
    nowTime(time) {
      return formatDate(time);
    },
    nowDay(day) {
      return formatDay2(day);
    },
    oneYear(day) {
      return formatDay2(day - 31104000000);
    },
  },
  created() {
    this.listenerFunction();
    this.getMessage();
  },
  mounted() {
    // // 更改 loading 文字
    // this.intervalID = setInterval(() => {
    //   this.remindShow === this.remindText.refresh
    //     ? this.remindShow = this.remindText.loading
    //     : this.remindShow = this.remindText.refresh
    // }, 4000)
    // // 监听滚动条事件
    // this.listenerFunction()
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction());
  },
  methods: {
    async getMessage() {
      //种植基地
      const data = await this.$API.trackBack.getPlantationBase({
        object: {
          id: this.$route.query.enterpriseId, //企业id
          // id: "ABB4A225178A4D889076698B2327A72B", //企业id
        },
      });
      this.zzjd = data.data.data;
      console.log("种植基地", this.zzjd);

      //检测认证
      const data2 = await this.$API.trackBack.getdetectionPdf({
        object: {
          barcode: this.$route.query.id,
          enterpriseId: this.$route.query.enterpriseId,
          // barcode: "6951184200196",
          // enterpriseId: "613748F65B9B4824A8DBB05045F0E19A",
        },
      });
      if (data2.data.data !== null) {
        this.jcrz = data2.data.data;
        this.jcrz.url = `http://pictures.realbrand.net/file/fileDownload/${
          data2.data.data.url.split("//")[1]
        } `;
        this.getTitlePdfurl();
      }
      console.log("检测认证", this.jcrz);

      //有机认证
      const data3 = await this.$API.trackBack.getOrganicDetails({
        object: {
          enterpriseId: this.$route.query.enterpriseId,
          // enterpriseId: '8A7568B3E7E345978D72F02805E8BE98',
          // barcode: "6951184200219",
          barcode: this.$route.query.id,
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.yjrz = data3.data.data;
      console.log("有机认证", this.yjrz);

      //工厂
      const data4 = await this.$API.trackBack.getFactoryList({
        object: {
          enterpriseId: this.$route.query.enterpriseId,
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.gc = data4.data.data;
      console.log("工厂", this.gc);

      //保险
      const data5 = await this.$API.trackBack.getInsuranceDetails({
        object: {
          commodityCode: this.$route.query.id,
          enterpriseId: this.$route.query.enterpriseId,
          // commodityCode: "1123235566",
          // enterpriseId: "ABB4A225178A4D889076698B2327A72E",
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.bx = data5.data.data;
      console.log("保险", this.bx);

      //消费者
      //获取店铺信息

      const data6 = await this.$API.homePage.enterprise({
        object: {
          barcode: this.$route.query.id,
          // barcode: '6959315400191',
          commodityCode: "",
          enterpriseId: this.$route.query.enterpriseId,
          // enterpriseId:'24754B81C38940D3B946F36FB7BECD4B',
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.Store = data6.data.data;
      console.log("消费者", this.Store);

      const data7 = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.query.id,
          // barcode: '6959315400191',
          enterpriseId: this.$route.query.enterpriseId,
          // enterpriseId: '24754B81C38940D3B946F36FB7BECD4B',
        },
      });
      this.CommodityDetails = data7.data.data;
      console.log(this.CommodityDetails);
    },
    getTitlePdfurl() {
      this.pdfSrc = pdf.createLoadingTask({
        url: this.jcrz.url,
        CMapReaderFactory,
      }); //解决中文乱码问题
      this.pdfSrc.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
      console.log(this.pdfSrc);
    },

    OnLi(i) {
      this.isShow = i;
    },
    up() {
      if (this.isShow === 0) {
        this.isShow = 10;
      } else {
        this.isShow--;
      }
    },
    down() {
      if (this.isShow >= 10) {
        this.isShow = 0;
      } else {
        this.isShow++;
      }
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      if (window.pageYOffset >= 314) {
        this.$refs.content.style.paddingTop = "46px";
        this.$refs.nav.style.position = "fixed";
      } else {
        this.$refs.content.style.paddingTop = "0";
        this.$refs.nav.style.position = "relative";
      }
    },
    copy() {
      let clipboard = new this.clipboard("#express_info_right");
      clipboard.on("success", function () {
        alert("复制成功");
        // that.$message.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", function () {
        alert("复制失败");
        // that.$message.error("复制失败");
      });
    },

    etter(str) {
      var result;
      var reg = /[a-zA-Z]+/; //[a-zA-Z]表示匹配字母，g表示全局匹配
      while ((result = str.match(reg))) {
        //判断str.match(reg)是否没有字母了
        str = str.replace(result[0], ""); //替换掉字母  result[0] 是 str.match(reg)匹配到的字母
      }
      return str;
    },

    goA(i) {
      console.log(i);
      if (i === 1) {
        window.open("http://taisheng.realbrand.net", "_blank");
      } else if (i === 3) {
        window.open("http://www.guorenpcic.com/web/bdyz/index.jhtml", "_blank");
      } else if (i === 5) {
        this.isShow = 5;
        // window.open("https://www.ky-express.com", "_blank");
      }
    },
    goB(i) {
      console.log(i);
      if (i === 1) {
        window.open("http://www.leishushuchayou.com", "_blank");
      } else if (i === 3) {
        window.open("http://www.guorenpcic.com/web/bdyz/index.jhtml", "_blank");
      } else if (i === 5) {
        this.isShow = 4;

        // window.open("https://www.ky-express.com", "_blank");
      }
    },
    goC(i, isShow) {
      console.log(i, isShow);
      if (isShow === 9) {
        if (i === 1) {
          window.open(
            "http://www.starway.com.cn/cn/fgslb/info_55.aspx?itemid=65",
            "_blank"
          );
        } else if (i === 3) {
          window.open(
            "http://www.guorenpcic.com/web/bdyz/index.jhtml",
            "_blank"
          );
        } else if (i === 5) {
          this.isShow = 4;

          // window.open("https://www.ky-express.com", "_blank");
        }
      }
    },
    goD(i, isShow) {
      console.log(i, isShow);
      if (isShow === 9) {
        if (i === 1) {
          window.open("http://www.gdyfyc.com/", "_blank");
        } else if (i === 3) {
          window.open(
            "http://www.guorenpcic.com/web/bdyz/index.jhtml",
            "_blank"
          );
        } else if (i === 5) {
          this.isShow = 4;

          // window.open("https://www.ky-express.com", "_blank");
        }
      }
    },

    //查询验证码 王怀国以前需求
    query() {
      if (this.userLogin || this.isLogin) {
        this.authCodeState = true;
      } else {
        this.$confirm("需要登录查看验证码，是否去到登录页", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            location.href = this.$URL;
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#track-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-top: 2px solid var(--subjectColor);

  .website {
    width: 1200px;
    height: 56px;
    margin: 30px 0;
    display: flex;
    img {
      // width: 102px;
      height: 56px;
      border: 1px solid #ebebeb;
      // background: #006cce;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 16px;
      p {
        font-size: 14px;
        color: #666666;
      }
      a {
        font-size: 16px;
        color: #438afe;
      }
    }
    .cxyzm {
      margin-left: 60px;
    }
  }
  .website:nth-child(11) {
    img {
      border: none;
    }
  }
  .content_box {
    width: 1200px;
    .nav {
      display: flex;
      top: -1px;
      width: 1200px;
      z-index: 999;
      background: #fbfbfb;
      border: 1px solid #e8e8e8;
      align-items: center;
      position: relative;
      div {
        width: 1200px;
        overflow: hidden;
        ul {
          display: flex;
          justify-content: center;
          position: relative;
          left: 0;
          top: 0;
          transition: all 0.3s;

          li {
            display: flex;
            align-items: center;
            height: 46px;
            .svg-icon {
              margin-right: 6px;
              color: #aaa;
              font-size: 16px;
            }
            img {
              margin: 0 16px;
            }
            p {
              // width: 56px;
              // text-align: center;
              font-size: 14px;
              color: #666666;
              cursor: pointer;
            }
          }
          li:last-child {
            img {
              display: none;
            }
          }
          .onLi {
            p {
              color: #41ad64;
            }
            .svg-icon {
              margin-right: 6px;
              color: #41ad64;
            }
          }
        }
      }
      .arrow {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        width: 14px;
        height: 46px;
        cursor: pointer;
        font-size: 14px;
        margin: 0 12px;
        color: #999;
      }
      .arrow:hover {
        color: #41ad64;
      }
    }
    .content:nth-child(8),
    .content:nth-child(9),
    .content:nth-child(10),
    .content:nth-child(11),
    .content:nth-child(17),
    .content:nth-child(18) {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 1200px;
      // height: 1300px;
      border: 1px solid #e8e8e8;
      border-top: none;
      margin-bottom: 30px;
      position: relative;
      padding: 40px 0;
      .documents {
        // width: 680px;
        // height: 1000px;
        // cursor: pointer;
        width: 100%;
        // height: auto;
      }
      .imgBox {
        width: 727px;
        height: 1030px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        overflow-x: hidden;
        align-items: center;
        justify-content: center;
        ::v-deep .el-loading-mask {
          z-index: 0;
        }
        .zzjd {
          background: #fbfbfb;
          padding: 50px 62px;
          table {
            caption {
              height: 35px;
              font-size: 24px;
              font-weight: bold;
              color: #111111;
              line-height: 35px;
              margin-bottom: 16px;
            }
            tr {
              font-size: 12px;
              border-color: #666666;
              a {
                color: #438afe;
              }
              td:nth-child(2),
              td:nth-child(4) {
                width: 210px;
                padding: 8px 10px;
                color: #333;
              }
              td:nth-child(1),
              td:nth-child(3) {
                width: 90px;
                padding: 8px 20px;
                color: #666;
              }
            }
          }
        }
        .gc {
          background: #fbfbfb;
          padding: 50px 62px;
          table {
            caption {
              height: 35px;
              font-size: 24px;
              font-weight: bold;
              color: #111111;
              line-height: 35px;
              margin-bottom: 16px;
            }
            tr {
              font-size: 12px;
              border-color: #666666;
              a {
                color: #438afe;
              }
              td:nth-child(2),
              td:nth-child(4) {
                width: 210px;
                padding: 8px 10px;
                color: #333;
              }
              td:nth-child(1),
              td:nth-child(3) {
                width: 90px;
                padding: 8px 20px;
                color: #666;
              }
              td:nth-child(1) {
                padding: 8px 14px;
              }
            }
          }
        }
      }
      .arrow {
        width: 40px;
        height: 84px;
        background: #000000;
        opacity: 0.15;
        position: absolute;
        top: 50%;
        margin-top: -42px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
      .arrow-left {
        left: 196px;
      }
      .arrow-right {
        right: 195px;
      }
      > p {
        position: absolute;
        top: 462px;
        left: 50%;
        margin-left: -18px;
        cursor: pointer;
        background: var(--subjectColor);
        color: #fff;
        width: 36px;
        height: 18px;
      }
      .Box {
        width: 720px;
        height: 1000px;
        overflow: auto;
        padding: 0 24px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .cont1 {
          padding: 50px 0 86px 0;
          border-bottom: 1px solid #e6e6e6;
          color: #333333;
          img {
            width: 106px;
            height: 106px;
            border: 1px solid #dcdcdc;
            border-radius: 50%;
          }
          p {
            margin: 24px 0 26px 0;
            font-size: 36px;
            font-family: PingFang SC;
          }
          h3 {
            // font-weight: bold;
            font-size: 46px;
          }
        }
        .cont2,
        .cont3 {
          padding: 48px 0;
          border-bottom: 1px #e7e7e7 dashed;
          ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            li {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .cont3 ul {
          margin-bottom: 20px;
          .name {
            width: 200px;
            padding-right: 20px;
            /*文本强制不换行*/

            white-space: nowrap;

            /*文本溢出显示省略号*/

            text-overflow: ellipsis;

            /*溢出的部分隐藏*/

            overflow: hidden;
          }
          .storeName {
            width: 500px;
            /*文本强制不换行*/

            white-space: nowrap;

            /*文本溢出显示省略号*/

            text-overflow: ellipsis;

            /*溢出的部分隐藏*/

            overflow: hidden;
          }
        }
        .cont4,
        .cont5 {
          padding: 48px 0;
          border-bottom: 1px solid #e7e7e7;
          div {
            font-size: 28px;
            flex-direction: row;
            width: 100%;
            margin-bottom: 16px;
            p {
              width: 112px;
              line-height: 40px;
              color: #999999;
            }
            span {
              margin-left: 52px;
              color: #333333;
            }
            a {
              margin-left: 52px;
              color: #438afe;
              text-decoration: underline;
            }
          }
        }
      }
      // .text {
      //   width: 680px;
      //   // height: 276px;
      //   background: #ffffff;
      //   border: 1px solid #e8e8e8;
      //   margin-top: 20px;
      //   .title {
      //     height: 42px;
      //     background: #f8f9fb;
      //     border-bottom: 1px solid #e8e8e8;
      //     font-size: 16px;
      //     font-family: Microsoft YaHei;
      //     font-weight: bold;
      //     color: #333333;
      //     display: flex;
      //     align-items: center;
      //     padding-left: 20px;
      //   }
      //   .cont {
      //     padding: 24px;
      //     h3 {
      //       font-size: 16px;
      //       font-family: Source Han Serif SC;
      //       font-weight: bold;
      //       line-height: 24px;
      //       color: #036eb8;
      //     }
      //     p {
      //       font-size: 14px;
      //       line-height: 28px;
      //       color: #666666;
      //       margin-top: 12px;
      //       a {
      //         color: #438afe;
      //       }
      //     }
      //   }
      // }
    }
  }
  .amplification_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.3);
    p {
      position: fixed;
      right: 16px;
      top: 0;
      cursor: pointer;
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      width: 80px;
      height: 30px;
      background: rgba($color: #000000, $alpha: 0.5);
      img {
        vertical-align: middle;
      }
    }
  }
}
</style>